<script setup lang="ts">
  import { QSkeleton } from 'quasar'

  const props = defineProps<{
    size: string
    src?: string
    loading?: boolean
  }>()

  const BaseIcon = defineAsyncComponent(async () => {
    const module = await import('~/shared/ui/svg')
    return module.AvatarIcon
  })
  const avatar = computed(() => {
    if (props.loading) {
      return {
        component: QSkeleton,
        src: ''
      }
    } else if (props.src) {
      return {
        component: 'img',
        src: props.src
      }
    }
    return {
      component: BaseIcon,
      src: ''
    }
  })
</script>

<template>
  <q-avatar
    class="user-avatar"
    :size="size"
  >
    <component
      :is="avatar.component"
      :src="avatar.src"
      :height="size"
      :width="size"
    />
  </q-avatar>
</template>

<style scoped lang="scss">
  .user-avatar {
    border-radius: 50% !important;
    & img {
      object-fit: cover;
    }
  }
</style>
